// commons
$default-padding: 25px;
$default-border-radius: 10px;

// layout components
$sidebar-width: 288px;
$main-menu-height: 64px;
$folder-view-toolbar-height: 62px;
$folder-view-content-height: calc(100vh - #{$main-menu-height + $folder-view-toolbar-height});
$modal-header-height: 55px;

//typography
$sider-menu-title: 22px;
$sider-menu-item: 14px;

//weights
$light: 300;
$normal: 400;
$medium: 500;
$semi: 600;
$bold: 700;
$black: 900;

