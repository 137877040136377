@import "./custom-antd-theme.css";
//@import "~antd/dist/antd.variable.min.css";

body {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
  font-family: "Inter Semibold", sans-serif;
  //overflow: hidden;
}
