@import "../../../styles/vars";
@import "../../../styles/colors";

.container {
  background: red;
  height: 100vh;
}

.sidebar {
  background: $light-grey;
  width: $sidebar-width;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.logo {
  padding: 10px !important;
  margin-bottom: $default-padding;
}

.header {
  background: $white;
  border-bottom: 1px solid $light-grey;
  height: auto;
  line-height: normal;
  padding: 8px 50px;

  :global .anticon-setting {
    &:hover {
      color: $active-color;
    }
  }
}

.menu {
  background: transparent;
  padding: $default-padding;

  :global .ant-menu-submenu {
    .ant-menu-submenu-title {
      background-color: $white;
      border-radius: $default-border-radius;
      margin-bottom: 10px;

      &:hover {
        color: $active-color;

        .ant-menu-submenu-arrow {
          color: $active-color;
        }
      }
    }

    .ant-menu-sub {
      border-radius: $default-border-radius;
      margin-bottom: $default-padding;

      .ant-menu-item {
        border-radius: $default-border-radius;
        margin: 0;

        &:hover {
          color: $active-color;
          background: transparent;

          .ant-menu-submenu-arrow, .ant-menu-title-content a {
            color: $active-color;
          }
        }

        &-selected {
          color: $white;
          background: $active-color;

          .ant-menu-submenu-arrow, .ant-menu-title-content a {
            color: $white;
          }

          &::after {
            border-right: none
          }
        }
      }
    }

    &-selected {
      color: $active-color;
    }

    &:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
      color: $active-color;
    }
  }
}

.content-container {
  overflow-y: auto;
}

