.container {
  background: #333;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 300px;
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  width: 64px;
  height: 64px;
  text-align: center;
}
