@font-face {
    font-family: 'Inter Semibold';
    src: url('Inter-SemiBold.otf') format('opentype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Inter Black';
    src: url('Inter-Black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Inter Bold';
    src: url('Inter-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Inter Regular';
    src: url('Inter-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter Light';
    src: url('Inter-Light.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Inter ExtraBold';
    src: url('Inter-ExtraBold.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

